import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    startDate: localStorage.getItem("client") === "Setu" ? new Date(new Date("2025-01-06").getTime() - 330 * 60000) : new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate()),
    endDate: new Date(new Date().setDate(new Date().getDate() - 1)),
    storeNumbers: 'all'
};

const dateRangeSlice = createSlice({
    name: 'dateRange',
    initialState,
    reducers: {
        setDateRangeInner: (state, action) => {
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
            state.storeNumbers = action.payload.storeNumbers;
        }
    }
});

export const { setDateRangeInner } = dateRangeSlice.actions;
export default dateRangeSlice.reducer;